import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { getDistanceFromLatLonInKm } from '../../util/data';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionDentalOfficeInfoMaybe = props => {
  const { description, publicData, showAsIngress, currentBrowserGeolocation, geolocation } = props;

  const textClass = showAsIngress ? css.ingress : css.text;

  const location = publicData?.location?.address;
  const businessHoursTelephone = publicData?.businessHoursTelephone?.phoneNumber;
  const emergencyTelephone = publicData?.emergencyTelephone?.phoneNumber;
  const emailAddress = publicData?.emailAddress;

  const distanceBetweenListings = getDistanceFromLatLonInKm(
    [currentBrowserGeolocation?.lat, currentBrowserGeolocation?.lng],
    [geolocation.lat, geolocation.lng]
  );

  return (
    <div>
      <div className={classNames(css.sectionText, css.sectionTextFirstChild)}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          <FormattedMessage id="ListingPage.description" />
        </Heading>
        {businessHoursTelephone &&
          <p className={textClass}>
            <label>
              <FormattedMessage id="ListingPage.businessHoursTelephone" />
            </label>
            {businessHoursTelephone}
          </p>
        }
        {emergencyTelephone &&
          <p className={textClass}>
            <label>
              <FormattedMessage id="ListingPage.emergencyTelephone" />
            </label>
            {emergencyTelephone}
          </p>
        }
        {emailAddress &&
          <p className={textClass}>
            <label>
              <FormattedMessage id="ListingPage.emailAddress" />
            </label>
            {emailAddress}
          </p>
        }
        {location &&
          <p className={classNames(css.ingress, css.ingressLast, css.ingressLocation)}>
            <label>
              <FormattedMessage id="ListingPage.dentalPracticeAddress" />
            </label>
            <span>
              {location}
              {!!currentBrowserGeolocation?.lat && !!currentBrowserGeolocation?.lng && (
                <span className={css.distance}>
                  <FormattedMessage id="ListingPage.distanceText" values={{distance: distanceBetweenListings}}/>
                </span>
              )}
            </span>
          </p>
        }
      </div>
    </div>
  );
};

export default SectionDentalOfficeInfoMaybe;
