import React, { useState } from 'react';
import { Heading, PropertyGroup, IconArrowHead, IconFile } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import defaultConfig from '../../config/configDefault';
import classNames from 'classnames';
import { languagesHelper as language } from '../../helpers/languages';

import css from './ListingPage.module.css';

const NO_IMAGE_FORMATS = ['PDF', 'WORD', 'WORDX', 'EXCEL', 'EXCELX', 'DCM', 'PLAIN', 'TXT', 'XLSX', 'DOC'];

const SectionDentistInfoMaybe = props => {
  const { publicData, showAsIngress, intl } = props;

  const textClass = showAsIngress ? css.ingress : css.text;

  const dentistInfo = !!publicData?.dentistInfo?.[0] ? publicData?.dentistInfo : [];
  const typeOfScaner = publicData?.typeOfScaner;
  const scannerManufacturers = publicData?.scannerManufacturers;
  const scannerModel = publicData?.scannerModel;
  const serviceDuration = publicData?.serviceDuration;

  const basicServices = publicData?.basicServices;
  const optionalServicescleaningAndTartarRemoval = publicData?.optionalServicescleaningAndTartarRemoval;
  const optionalServicesdentalCheckupExam = publicData?.optionalServicesdentalCheckupExam;
  const technician = publicData?.technician;
  const additionalDetails = publicData?.additionalDetails;
  const dentistFiles = publicData?.dentistFiles;

  const [currentDentistId, setCurrentDentistId] = useState(0)
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0)

  const onHandleSetCurrentIndex = (type, index) => {

    if (type === 'dentist') {
      if (index === currentDentistId) setCurrentDentistId(-1)
      else setCurrentDentistId(index)
    }
    else {
      if (index === currentServiceIndex) setCurrentServiceIndex(-1)
      else setCurrentServiceIndex(index)
    }
  }

  let currentLocale = language.get();
  const countriesList = currentLocale === 'fr' ? defaultConfig?.countriesListFr : defaultConfig?.countriesListEn

  return (
    <>

      {!!dentistInfo?.[0] && (
        <div className={css.sectionText}>
          <Heading as="h2" rootClassName={css.sectionHeading}>
            <FormattedMessage id="ListingPage.dentistInformation" />
          </Heading>

          <ul className={css.infoList}>
            {dentistInfo.map((dentist, index) => {

              const prescriptionFile = dentistFiles?.find(f => f.id === dentist?.id)

              return (
                <li className={classNames(
                  css.infoListItem,
                  { [css.infoListItemActive]: currentDentistId === index }
                )}>
                  <h4
                    className={css.infoListItemTitle}
                    onClick={() => onHandleSetCurrentIndex('dentist', index)}
                  >
                    <span>
                      {`Dentist ${index + 1}`}
                      {` - ${dentist.firstName}`}
                      {!!dentist?.lastName && ` ${dentist.lastName}`}
                    </span>

                    <IconArrowHead direction={currentDentistId === index ? 'up' : 'down'} />
                  </h4>
                  <div className={css.infoListItemContent}>
                    {!!dentist?.firstName &&
                      <p className={textClass}>
                        <label>
                          <FormattedMessage id="ListingPage.dentistName" />
                        </label>
                        {dentist.firstName}
                        {!!dentist?.lastName && ` ${dentist.lastName}`}
                      </p>
                    }
                    {!!dentist?.gender &&
                      <p className={textClass}>
                        <label>
                          <FormattedMessage id="ListingPage.gender" />
                        </label>
                        <span className={css.capitalizeText}>{dentist.gender}</span>
                      </p>
                    }
                    {!!dentist?.birthDate &&
                      <p className={textClass}>
                        <label>
                          <FormattedMessage id="ListingPage.dateOfBirth" />
                        </label>
                        <span>
                          {dentist?.birthDate?.day < 10 ? `0${dentist.birthDate?.day}` : dentist.birthDate?.day}.
                          {dentist?.birthDate?.month < 10 ? `0${dentist.birthDate?.month}` : dentist.birthDate?.month}.
                          {dentist?.birthDate.year}
                        </span>
                      </p>
                    }

                    {!!dentist?.dentalAssociations &&
                      <p className={textClass}>
                        <label>
                          <FormattedMessage id="ListingPage.dentalAssociationMembership" />
                        </label>
                        {
                          dentist?.dentalAssociations === 'other' && !!dentist?.dentalAssociationsOther ?
                            dentist.dentalAssociationsOther :
                            language.labelsTranslator(dentist.dentalAssociations, intl)
                        }
                      </p>
                    }

                    {!!dentist?.dentalLicenseNumber &&
                      <p className={textClass}>
                        <label>
                          <FormattedMessage id="ListingPage.dentalLicenseNumber" />
                        </label>
                        {dentist.dentalLicenseNumber}
                      </p>
                    }

                    {!!dentist?.dentalLicenseCountry &&
                      <p className={textClass}>
                        <label>
                          <FormattedMessage id="ListingPage.dentalLicenseCountry" />
                        </label>
                        {countriesList?.find(c => c.key === dentist?.dentalLicenseCountry)?.label || publicData?.dentist?.[index]?.dentalLicenseCountry}
                      </p>
                    }

                    {prescriptionFile && (
                      <p className={classNames(textClass, css.ingressLast, css.ingressBlock)}>
                        <label>
                          <FormattedMessage id="ListingPage.dentalLicense" />
                        </label>
                        <div className={css.fileWrapper}>
                          <a
                            className={css.fileLink}
                            href={prescriptionFile?.file?.location}
                            target='_blank'
                          >
                            {NO_IMAGE_FORMATS.includes(prescriptionFile?.file?.format?.toUpperCase()) ? (
                              <div className={css.noImage}>
                                <IconFile
                                  type={prescriptionFile?.file?.format?.toUpperCase()}
                                  size="large"
                                />
                                <span className={css.fileName}>{prescriptionFile?.file?.key}</span>
                              </div>
                            ) : (
                              <img
                                src={prescriptionFile?.file?.location}
                                alt="Prescription file"
                                className={css.filePreview}
                              />
                            )}
                          </a>
                        </div>
                      </p>
                    )}
                  </div>
                </li>
              )
            })}
          </ul>

        </div>
      )}

      <div className={classNames(css.sectionText, css.sectionTextLastChild)}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          <FormattedMessage id="ListingPage.typeOfScanner" />
        </Heading>

        <ul className={css.infoList}>
          {!!typeOfScaner?.length && typeOfScaner.map((scaner, index) => {

            const basicServicesOptions = scaner === 'cleaningAndTartarRemoval' ?
              defaultConfig.cleaningAndTartarRemovalBasicServices :
              scaner === 'dentalCheckupExam' ?
                defaultConfig.dentalCheckupBasicServices :
                defaultConfig?.scanBasicServices?.find(s => s.scan === scaner)?.options;

            return (
              <li className={classNames(
                css.infoListItem,
                { [css.infoListItemActive]: currentServiceIndex === index }
              )}>
                <h4
                  className={css.infoListItemTitle}
                  onClick={() => onHandleSetCurrentIndex('service', index)}
                >
                  <span>
                    {`Service ${index + 1}`}
                    {` - ${defaultConfig?.typeOfScanerOptions?.find(item => item.key === scaner).label}`}
                  </span>

                  <IconArrowHead direction={currentServiceIndex === index ? 'up' : 'down'} />
                </h4>

                <div className={css.infoListItemContent}>
                  <p className={css.ingress}>
                    <label>
                      <FormattedMessage id="ListingPage.typeOfScannerLabel" />
                    </label>
                    <span>
                      {defaultConfig?.typeOfScanerOptions?.find(item => item.key === scaner).label}
                    </span>
                  </p>

                  { (
                    <p className={classNames(css.ingress, css.ingressBlock)}>
                      <label>
                        <FormattedMessage id="ListingPage.basicServicesLabel" />
                      </label>

                      <PropertyGroup
                        id="ListingPage.basicServices"
                        options={basicServicesOptions}
                        // options={defaultConfig?.scanBasicServices?.find(s => s.scan === scaner)?.options || []}
                        selectedOptions={basicServices?.find(item => item?.type === scaner).basicServices}
                        withTranslations
                        intl={intl}
                      />
                    </p>
                  )}
                  {scaner === 'cleaningAndTartarRemoval' && !!optionalServicescleaningAndTartarRemoval && (
                    <p className={classNames(css.ingress, css.ingressBlock)}>
                      <label>
                        <FormattedMessage id="ListingPage.optionalServicesLabel" />
                      </label>

                      <ul className={css.optionalServicesList}>
                        {optionalServicescleaningAndTartarRemoval.map(item => {
                          return (<li>{item}</li>)
                        })}
                      </ul>
                    </p>
                  )}
                  {scaner === 'dentalCheckupExam' && !!optionalServicesdentalCheckupExam && (
                    <p className={classNames(css.ingress, css.ingressBlock)}>
                      <label>
                        <FormattedMessage id="ListingPage.optionalServicesLabel" />
                      </label>

                      <ul className={css.optionalServicesList}>
                        {optionalServicesdentalCheckupExam.map(item => {
                          return (<li>{item}</li>)
                        })}
                      </ul>
                    </p>
                  )}
                  {!!technician?.find(item => item.type === scaner)?.technician && (
                    <p className={css.ingress}>
                      <label>
                        <FormattedMessage id="ListingPage.technicianLabel" />
                      </label>
                      <span>
                        {technician?.find(item => item.type === scaner).technician}
                      </span>
                    </p>
                  )}
                  {!!scannerManufacturers?.find(item => item.type === scaner).manufacturer && (
                    <p className={css.ingress}>
                      <label>
                        <FormattedMessage id={(scaner === 'standardXRay' || scaner === 'panoramicXRay') ? 'ListingPage.xrayManufacturerLabel' : 'ListingPage.scannerManufacturerLabel'} />
                      </label>
                      <span>
                        {scannerManufacturers.find(item => item.type === scaner).manufacturer}
                      </span>
                    </p>
                  )}
                  {!!scannerModel?.find(item => item.type === scaner).model && (
                    <p className={css.ingress}>
                      <label>
                        <FormattedMessage id={(scaner === 'standardXRay' || scaner === 'panoramicXRay') ? 'ListingPage.xrayModelLabel' : 'ListingPage.scannerModelLabel'} />
                      </label>
                      <span>
                        {scannerModel.find(item => item.type === scaner).model}
                      </span>
                    </p>
                  )}
                  {!!additionalDetails?.find(item => item.type === scaner).additionalDetails && (
                    <p className={classNames(css.ingress, css.ingressBlock)}>
                      <label>
                        <FormattedMessage id="ListingPage.additionalDetailsLabel" />
                      </label>
                      <span>
                        {additionalDetails.find(item => item.type === scaner).additionalDetails}
                      </span>
                    </p>
                  )}

                  {!!serviceDuration?.find(item => item.type === scaner).serviceDuration && (
                    <p className={css.ingress}>
                      <label>
                        <FormattedMessage id="ListingPage.serviceDurationLabel" />
                      </label>
                      <span>
                        {language.labelsTranslator(serviceDuration.find(item => item.type === scaner).serviceDuration, intl)}
                      </span>
                    </p>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  );
};

export default SectionDentistInfoMaybe;
